import Button from "../components/button/Button"
import Hishtalmut from "../components/Hishtalmut/Hishtalmut"

import styles from "./FifthScreen.module.css"

const FifthScreen=()=>{


return <>
<div className={styles.title}></div>
    <Hishtalmut/>
    <Button text="סתיו,בואי נדבר!"/>
</>
}
export default FifthScreen